import * as Sentry from '@sentry/nextjs';
import getMyData from 'api/getMyData';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useUserData } from 'state/userData';
import useSWR from 'swr';

const FetchUserData = () => {
    const router = useRouter();
    const { setUserData, setLoginStatus, setIsFetched } = useUserData();

    const [isRedirectedToCvUpload, setIsRedirectedToCvUpload] = useState(false);

    const redirectHandlerCV = (withCV: boolean) => {
        if (withCV || isRedirectedToCvUpload) return;

        router.push('/sign-up/talent/2');
        setIsRedirectedToCvUpload(true);
    };

    const userFetcher = async () => {
        const { data } = await getMyData();
        return data;
    };

    const { data: userData, isLoading } = useSWR('/me/', userFetcher, {
        revalidateOnFocus: false,
        refreshInterval: 1200000,
    });

    useEffect(() => {
        if (isLoading) return;

        if (!userData) {
            setLoginStatus(false);
            Sentry.setContext('User Info', { userType: 'not logged in' });
        }

        if (userData) {
            setLoginStatus(true);

            setUserData({
                firstName: userData.first_name,
                lastName: userData.last_name,
                email: userData.email,
                phoneNumber: userData.phone_number,
                username: userData.username,
                location: userData.location,
                type: userData.type,
                role: userData.role,
                id: userData.id,
                profile: userData.profile,
                hasSmsVerification: userData.oob,
                hasAuthenticator: userData.otp,
                companySlug: userData.company_id,
                typeGroups: userData.groups,
                initials: userData.initials,
                activeCompany: userData.active_company,
            });

            const sentryLogData = {
                firstName: userData.first_name,
                lastName: userData.last_name,
                email: userData.email,
                userType: userData.type,
                userSlug: userData.slug || userData.anonymous_profile?.slug,
            };

            Sentry.setContext('User Info', sentryLogData);

            const isTalent = userData?.type === 'talent';
            const withCV = !!userData?.profile?.cv_file;

            isTalent && redirectHandlerCV(withCV);
        }

        setIsFetched(true);
    }, [userData, isLoading]);

    return null;
};

export default FetchUserData;
