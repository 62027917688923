import { IconProps } from '../types';

const ExclamationIcon: React.FC<IconProps> = ({
    width = 54,
    height = '100%',
    color = '#F88078',
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Exclamation">
                <path
                    data-testid="exclamation-icon"
                    id="Icon"
                    d="M27.0004 20.25V26.75M27.0004 33.75H27.0229M11.412 42.75H42.5889C46.053 42.75 48.2181 39 46.486 36L30.8976 9C29.1655 6 24.8354 6 23.1033 9L7.51487 36C5.78282 39 7.94789 42.75 11.412 42.75Z"
                    stroke={color}
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default ExclamationIcon;
