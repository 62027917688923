import { Organization } from 'api/getClientOrganizations';
import { UserLocation, UserProfile } from 'api/getMyData';
import { UserTypeGroups } from 'types/pageTypes';
import { create } from 'zustand';

export type AccountPrivacy = 'public' | 'anonymous';
export type UserRole =
    | 'professional'
    | 'client'
    | 'client admin'
    | 'partner'
    | null;

interface UserData {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    initials: string | null;
    phoneNumber: string | null;
    username: string | null;
    location: UserLocation | null;
    type: 'client' | 'talent' | 'expert' | null;
    role: UserRole;
    id: number | null;
    profile: Partial<UserProfile> | null;
    hasSmsVerification: boolean;
    hasAuthenticator: boolean;
    companySlug: string | null;
    privacy: AccountPrivacy;
    typeGroups: UserTypeGroups | null;
    activeCompany: Organization | null;
}

interface UserProfileState {
    isLoggedIn: boolean;
    isFetched: boolean;
    user: UserData;
    setUserData: (data: Partial<UserData>) => void;
    setLoginStatus: (isLogged: boolean) => void;
    setIsFetched: (isFetched: boolean) => void;
}

export const useUserData = create<UserProfileState>((set) => ({
    isLoggedIn: false,
    isFetched: false,

    user: {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        username: null,
        initials: null,
        location: null,
        type: null,
        role: null,
        id: null,
        profile: null,
        hasAuthenticator: false,
        hasSmsVerification: false,
        companySlug: null,
        privacy: 'public',
        typeGroups: null,
        activeCompany: null,
    },

    setUserData: (newUserData) =>
        set((state) => ({ user: { ...state.user, ...newUserData } })),

    setLoginStatus: (isLogged) => set({ isLoggedIn: isLogged }),

    setIsFetched: (isFetched) => set({ isFetched: isFetched }),
}));
