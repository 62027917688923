import { ComponentHeader } from 'types/componentTypes';
import {
    LocationTypes,
    TalentLanguagesType,
    UserTypeGroups,
} from 'types/pageTypes';
import axiosInstance from './axiosConfig';
import { GenderTypes } from './settings/patchUserProfile';
import { Organization } from './getClientOrganizations';

type StartEndDate = {
    date_from: string;
    to_date: string;
    is_soon: boolean;
};

export interface UserLocation {
    country: string;
    region: string;
    city: string;
    street: string;
    number_of_street: string;
    address: string;
    postal_code: string;
    additional_address_details: string;
    filled_manually: boolean;
}

export type UserProfile = {
    title: string;
    id: number;
    slug: string | null;
    first_name: string;
    last_name: string;
    initials: string;
    variant: 'tp' | 'ta';
    email: string;
    location_kind: string[];
    user_type: string;
    photo: {
        title: string;
        height: number;
        width: number;
        url: string;
    };
    cv_identifier: string | null;
    cv_file: string | null;
    exp_year: number;
    work_type: {
        type: 'part_time' | 'full_time';
        value: boolean;
        id: string;
    }[];
    amount_of_hours: number;
    job: string;
    employment: [
        {
            type: string;
            value: {
                contractor_employment: boolean;
                interim: boolean;
                employment: boolean;
                monthly_rate: number;
                hourly_rate: number;
            };
            id: string;
        }
    ];
    additional_job: {
        type: string;
        value: string;
        id: string;
    }[];
    phone_number: string;
    skills: {
        type: string;
        value: string;
        id: string;
    }[];
    about: string;
    experience: {
        type: string;
        value: {
            company_name: string;
            industry: string;
            size_company: number;
            job_title: string;
            country: string;
            city: string;
            description: string;
            start: string;
            end: string;
            is_working_here: boolean;
            skills: {
                type: string;
                value: string;
                id: string;
            }[];
            about: string;
        };
        id: string;
    }[];
    education: {
        type: string;
        value: {
            level: string;
            area_education: string;
            institute: string;
            country: string;
            city: string;
            in_progress: boolean;
            start: string;
            end: string;
        };
        id: string;
    }[];
    occupation_group: [];
    languages: TalentLanguagesType[];
    certifications: [];
    start_date: StartEndDate;
    end_date: StartEndDate;
    is_available: boolean;
    if_stripe_account: boolean;
    birth_date: string;
    gender: GenderTypes;
    calendar_info: {
        calendar_email: string;
        calendar_slug: string;
        has_social_calendar: boolean;
        id: number;
    };
};

export interface mainUserLocation {
    country: string;
    region: string;
    city: string;
    day_in_office: string;
    type: LocationTypes;
    street: string;
    number_of_street: string;
}

export interface additionalUserLocation {
    country: string;
    region: string;
    city: string;
    day_in_office: string;
    type: LocationTypes;
}

export interface customUserLocation {
    address: string;
    postal_code: string;
    additional_address_details: string;
    city: string;
    country: string;
}

interface Response {
    data: {
        header: ComponentHeader;
        id: number;
        first_name: string;
        last_name: string;
        email: string;
        phone_number: string | null;
        initials: string;
        username: string;
        location: UserLocation | null;
        type: 'client' | 'talent' | 'expert' | null;
        role: 'professional' | 'client' | 'client admin' | 'partner' | null;
        profile: UserProfile;
        oob: boolean;
        otp: boolean;
        company_id: string;
        groups: UserTypeGroups;
        slug: string;
        anonymous_profile?: { slug: string };
        active_company: Organization;
        default_company: Organization;
    };
}

const getMyData = (): Promise<Response> =>
    axiosInstance.get(`/wt/api/v1/me`, {
        withCredentials: true,
    });

export default getMyData;
