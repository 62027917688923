import { ToastOptions, toast } from 'react-toastify';
import CustomToast, { toastType } from './CustomToast';

type ToastFunction = (
    content: React.ReactNode | string,
    params?: ToastOptions
) => void;

const createToast = (
    toastMethod: (content: React.ReactNode, options?: ToastOptions) => void,
    type: toastType
): ToastFunction => {
    return (content, params) => {
        const parameters = { icon: false, ...params };
        toastMethod(<CustomToast content={content} type={type} />, parameters);
    };
};

const toastError = createToast(toast.error, 'error');
const toastInfo = createToast(toast.info, 'info');
const toastWarning = createToast(toast.warning, 'warning');
const toastSuccess = createToast(toast.success, 'success');

export { toastError, toastInfo, toastWarning, toastSuccess };
