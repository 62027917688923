import RuntimeConfigProvider from 'state/runtime-config-provider';
import MaintenanceBanner from 'components/MaintenanceBanner';
import UnleashFlagsProvider from '../UnleashFlagsProvider';
import UtmTagsProvider from '../UtmTagsProvider';
import getServerFlags from 'api/getServerFlags';
import { ThemeProvider } from '@mui/material';
import FetchUserData from '../FetchUserData';
import Favicon from 'components/Favicon';
import { theme } from 'theme/theme';
import useSWR from 'swr';
import { StyledToastContainer } from './AppProviders.styled';

const serverFlagsFetcher = async () => {
    const { data } = await getServerFlags();
    return data;
};

const AppProviders: React.FC = ({ children }) => {
    const { data: flags } = useSWR('/flags/', serverFlagsFetcher, {
        revalidateOnFocus: false,
    });

    return (
        <RuntimeConfigProvider>
            <Favicon />
            <UtmTagsProvider />
            <FetchUserData />
            <UnleashFlagsProvider>
                <ThemeProvider theme={theme}>
                    <StyledToastContainer />
                    <MaintenanceBanner {...flags} canBeClosed={true} />
                    {children}
                </ThemeProvider>
            </UnleashFlagsProvider>
        </RuntimeConfigProvider>
    );
};

export default AppProviders;
